<template>
  <!-- Header -->
  <div class="forScroll flex flex-nowrap text-teal w-full">
    <div class="flex-1 justify-start">
      <p class=" text-grey text-4xl font-bold pt-6 pl-5">
        {{ post.title }}
      </p>
      <h2 class=" text-grey font-semibold text-xl pl-5">
        <span class="text-darkGrey">Subject:</span> {{ post.subject }}
      </h2>
    </div>
    <div @click="toggleResponse()" class="flex items-center justify-end pr-3">
      <span class="material-icons text-3xl cursor-pointer">
        <a href="#responseBox"> </a> add_circle_outline
      </span>
    </div>
  </div>
  <!-- Attachments -->
  <div
    v-if="post.post_attachments.length"
    class="flex flex-nowrap text-teal w-full pl-5 pt-2"
  >
    <p>
      <span class="text-darkGrey"
        ><span class="font-semibold">Attachments: </span>
        <span
          v-for="(attachment, index) in post.post_attachments"
          :key="attachment.id"
          ><a :href="attachment.url" target="_blank"
            ><span class="material-icons text-sm"> picture_as_pdf </span>File{{
              index + 1
            }}</a
          ></span
        >
      </span>
    </p>
  </div>
  <forum-message
    :postDetails="post"
    :showResponse="showResponse"
    @answer-posted="submitAnswer"
    @toggle-response="toggleResponse"
    @comment-posted="submitComment"
  ></forum-message>
</template>
<script>
import forumMessage from "./forumMessage.vue";
export default {
  components: { forumMessage },
  emits: ["reload-post"],
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    if (this.$route.query.new) {
      this.$toast.success(`Post Created!`);
    }
  },
  data() {
    return {
      isLoading: false,
      showResponse: false,
    };
  },
  methods: {
    async submitAnswer(payload) {
      try {
        let formdata = new FormData();
        formdata.append("post", this.$route.params.threadId);
        formdata.append("answer", payload);
        await this.$store.dispatch("cleaner/newAnswer", formdata);
        this.showResponse = false;
        this.$toast.success(`Answer Posted!`);
        this.$emit("reload-post");
      } catch (error) {
        this.showResponse = false;
        this.$toast.error(`Oops. Something went wrong!`);
      }
    },
    toggleResponse() {
      this.showResponse = !this.showResponse;
    },
    async submitComment(data) {
      try {
        let formdata = new FormData();
        formdata.append("post", this.$route.params.threadId);
        formdata.append("comment", data);
        await this.$store.dispatch("cleaner/newComment", formdata);
        this.$toast.success(`Comment Posted!`);
        this.$emit("reload-post");
      } catch (error) {
        this.$toast.error(`Oops. Something went wrong!`);
      }
    },
  },
};
</script>

<style scoped>
.forScroll {
  scroll-behavior: smooth;
}
</style>

<template>
  <base-dialog
    :show="!!error"
    :errorIcon="!!error"
    title="An error occurred"
    @close="handleError"
  >
    <p>{{ error }}</p>
  </base-dialog>
  <template v-if="!isLoading">
    <thread-layout :post="getPost" @reload-post="updatePost"></thread-layout>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
</template>
<script>
import threadLayout from "@/components/layout/CS/threadLayout.vue";
export default {
  components: { threadLayout },
  data() {
    return {
      isLoading: false,
      signatureModalShow: false,
      hideModal: true,
      error: null,
    };
  },
  created() {
    this.loadSinglePost();
    this.recordPostView();
  },
  methods: {
    async loadSinglePost() {
      this.isLoading = true;
      try {
        let formdata = new FormData();
        formdata.append("post", this.$route.params.threadId);
        await this.$store.dispatch("cleaner/loadSinglePost", formdata);
        this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async updatePost() {
      try {
        let formdata = new FormData();
        formdata.append("post", this.$route.params.threadId);
        await this.$store.dispatch("cleaner/loadSinglePost", formdata);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async recordPostView() {
      try {
        let formdata = new FormData();
        formdata.append("post", this.$route.params.threadId);
        await this.$store.dispatch("cleaner/recordPostView", formdata);
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    handleError() {
      this.error = null;
    },
  },
  computed: {
    getPost() {
      return this.$store.getters["cleaner/getPost"];
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  color: #425563 !important;
  font-weight: 700;
}
</style>

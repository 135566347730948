<template>
  <div class="flex items-center border-t w-11.5/12 border-grey m-auto py-2">
    <div class="flex w-full m-1 justify-between">
      <p class="ml-1 text-sm" v-html="postComment.comment"></p>

      <span class="text-grey text-xs flex items-end">
        {{ postComment.user.email }}:
        {{ convertedTime(postComment.created_at) }}</span
      >
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    postComment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>

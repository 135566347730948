<template>
  <template v-if="!isLoading">
    <!-- Message Container -->
    <div
      class="flex-col bg-lightGrey border border-lightGrey rounded-lg w-11.5/12 items-center mx-auto my-3"
    >
      <!-- Top Section -->
      <div class="flex items-center">
        <!-- User Icon -->
        <div class="flex m-1 w-full">
          <div
            class="rounded-full h-8 w-8 flex items-center justify-center text-darkGrey"
          >
            <!-- <img
                    v-if="!imageError"
                    alt="Profile"
                    @error="setAltImg"
                    :src="profileData.profile.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full "
                  /> -->
            <img
              alt="Profile"
              src="../../../assets/1.png"
              class="mx-auto object-cover rounded-full w-full h-full "
            />
          </div>
          <div class="m-2">
            <p class="text-darkGrey text-xs">
              Posted By <strong>{{ postDetails.user.email }}</strong> -
              {{ convertedTime(postDetails.created_at) }}
            </p>
          </div>
        </div>
      </div>
      <!-- Body Section -->
      <div class="flex items-center">
        <div class="flex w-full m-1">
          <p class="ml-2">
            <span v-html="postDetails.message"></span>
          </p>
        </div>
      </div>
      <!-- Tags -->
      <div class="flex items-center py-1">
        <div class="flex w-full m-1">
          <span
            class="flex items-center ml-1 px-3 py-0.1 rounded-full text-darkBlue text-sm bg-lightestBlue hover:bg-lightBlue"
          >
            {{ postDetails.category.toUpperCase() }}
          </span>
        </div>
      </div>
      <!-- Comments -->
      <div class="flex items-center py-1">
        <div class="flex-col w-full m-1">
          <div class="flex">
            <span class="material-icons text-teal ml-1">
              question_answer
            </span>
            <p class="ml-1 cursor-pointer underline" @click="toggleComment">
              {{ postDetails.comments_count }} Comments
            </p>
          </div>
          <!-- Comment Box -->
          <transition name="fade">
            <div v-if="showCommentBox" class="sm:flex w-full py-2 ml-1">
              <div class="sm:flex-col bg-white h-full sm:w-10/12 w-full">
                <QuillEditor
                  style="min-height:100px;"
                  toolbar="essential"
                  theme="snow"
                  contentType="html"
                  v-model:content="commentData"
                />
              </div>
              <div
                class="w-full sm:flex-col flex sm:w-2/12 pt-2 items-center sm:items-start justify-center sm:justify-start"
              >
                <div class="inline sm:flex  mx-2">
                  <button
                    @click="resolveComment"
                    type="submit"
                    class="duration-200 ease-in-out border-2 border-teal bg-teal hover:border-lightBlue hover:bg-lightBlue rounded-full w-20"
                  >
                    Submit
                  </button>
                </div>
                <div class="inline sm:flex py-2 mx-2">
                  <button
                    type="submit"
                    class="duration-200 ease-in-out border-2 border-orange bg-orange hover:bg-lightOrange hover:border-lightOrange rounded-full w-20"
                    @click="toggleComment"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <span v-if="postDetails.comments_count">
        <!-- Comments -->
        <thread-comment
          v-for="comment in postDetails.comments"
          :key="comment.id"
          :postComment="comment"
        >
        </thread-comment>
      </span>
    </div>

    <!-- Response Input Box -->
    <transition name="fade">
      <div
        v-if="showResponse"
        class="bg-lightGrey border border-lightGrey rounded-lg w-11.5/12 mx-auto"
        id="responseBox"
      >
        <div class="flex w-full justify-center my-2">
          <div class="sm:flex-col bg-white h-full w-11.5/12">
            <QuillEditor
              style="min-height:100px;"
              toolbar="essential"
              theme="snow"
              contentType="html"
              v-model:content="responseData"
            />
          </div>
        </div>
        <div class="pb-2 mx-2">
          <button
            @click="resolveAnswer"
            type="submit"
            class="duration-200 ease-in-out mx-2 border-2 border-teal bg-teal hover:border-lightBlue hover:bg-lightBlue rounded-full w-20"
          >
            Submit
          </button>
          <button
            @click="toggleResponse"
            type="submit"
            class="duration-200 ease-in-out border-2 border-orange bg-orange hover:bg-lightOrange hover:border-lightOrange rounded-full w-20"
          >
            Cancel
          </button>
        </div>
      </div>
    </transition>

    <!-- Response Container -->
    <span v-if="postDetails.answers.length > 0">
      <thread-response
        v-for="answer in postDetails.answers"
        :key="answer.id"
        :postAnswer="answer"
      >
      </thread-response>
    </span>
    <span v-else>
      <div
        class="flex w-11.5/12 m-auto border-dashed justify-center border-2 bg-lightGrey border-darkGrey rounded-2xl p-10"
      >
        No answers!
      </div>
    </span>
  </template>
  <template v-else>
    <heart-rate fast="true"></heart-rate>
  </template>
</template>

<script>
import moment from "moment";
import threadComment from "./threadComment.vue";
import threadResponse from "./threadResponse.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
export default {
  props: {
    postDetails: {
      type: Object,
      required: true,
    },
    showResponse: {
      type: Boolean,
      required: true,
    },
  },
  created() {
  },
  emits: ["answer-posted", "comment-posted", "toggle-response"],
  components: { threadComment, threadResponse, QuillEditor },
  data() {
    return {
      isLoading: false,
      responseData: null,
      commentData: null,
      showCommentBox: false,
    };
  },
  methods: {
    getInitials(name) {
      let parts = name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      return initials;
    },
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    toggleComment() {
      this.showCommentBox = !this.showCommentBox;
    },
    toggleResponse() {
      this.$emit("toggle-response");
    },

    resolveAnswer() {
      this.$emit("answer-posted", this.responseData);
      this.responseData = null;
    },
    resolveComment() {
      this.$emit("comment-posted", this.commentData);
      this.commentData = null;
      this.showCommentBox = !this.showCommentBox;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.ql-editor {
  min-height: 200px;
}
</style>

<template>
  <div
    class="
      flex-col
      bg-lightGrey
      border border-lightGrey
      rounded-lg
      w-11.5/12
      items-center
      mx-auto
      my-5
    "
  >
    <!-- Top Section -->
    <div class="flex items-center">
      <!-- User Icon -->
      <div class="flex w-11/12 m-1">
        <div
          class="
            rounded-full
            h-8
            w-8
            border-pink
            bg-pink
            flex
            items-center
            justify-center
            text-white
          "
        >
          {{ postAnswer.user_id }}
        </div>
        <div class="m-2">
          <p class="text-darkGrey text-xs">
            Posted By <strong>{{ postAnswer.user.email }}</strong> -
            {{ convertedTime(postAnswer.created_at) }}
          </p>
        </div>
      </div>
    </div>
    <!-- Body Section -->
    <div class="flex items-center">
      <div class="flex w-11/12 m-1">
        <p class="ml-2">
          <span v-html="postAnswer.answer"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    postAnswer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
};
</script>
